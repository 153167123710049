import "@/assets/styles/index.less";
import "@/assets/styles/var.less";
import lozad from "lozad";

// gatsby-browser.js
const { observe } = lozad(".lozad", {
  loaded: (el) => {
    const { objectFit: finalObjectFit } = el.dataset;

    /**
     * TODO：
     * 这里是为了解决loading占位图不会适应，铺满所有尺寸的图片的问题，
     * 可以先将loading图的object-fit设置为cover,
     * 后面通过设置data-object-fit设置最终图片的object-fit
     */
    if (el.nodeName === 'IMG' && el instanceof HTMLImageElement) {
      el.onload = () => {
        if (typeof finalObjectFit !== "undefined") {
          el.style.objectFit = finalObjectFit
        }
        el.classList.add('fade');
      };
    } else if (el.nodeName === "VIDEO" && el instanceof HTMLVideoElement) {
      el.addEventListener("loadeddata", () => {
        if (typeof finalObjectFit !== 'undefined') {
          el.style.objectFit = finalObjectFit;
        }
        el.classList.add('fade');
      })
    } else {
      el.classList.add('fade');
    }
  },
});

export const onRouteUpdate = ({ location }) => {
  observe();
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);

  // 如果是hash的变化不应该做处理，因为hash的变化有可能是锚点的变化
  if (location.hash === "") {
    setTimeout(() => {
      window.scrollTo({
        top: currentPosition?.[1] || 0,
        left: currentPosition?.[0] || 0,
        behavior: "instant"
      });
    }, 100);
  }

  return false;
};
